import photo from "../images/main.jpg";

const AboutMe = () => {
	return (
		<div className="container">
			<div className="title">
				<h2>Обо мне</h2>
			</div>
			<article className="profile-container container">
				<div className="profile-text">
					<p>Здравствуйте! Меня зовут Евгения Гридина.</p>
					<p>
						Мне 30 лет, и с 2014 года я профессионально занимаюсь оформлением интерьеров и общественного пространства.
						За это время я реализовала проекты в таких городах, как Челябинск, Санкт-Петербург, Москва, Сочи и
						Ставрополь, а также вела проекты лично в Челябинске, Москве и Санкт-Петербурге.
						<br />
					</p>
					<p>
						Я – дипломированный специалист по направлению «Дизайн Среды» и предлагаю вам сотрудничество, основанное на
						моем опыте и индивидуальном подходе к каждому проекту. Я работаю с разными стилями, но отдаю особое
						предпочтение таким стилям как Минимализм, Джапанди, Хай-тек и Современная классика, эти стили создают
						солнечные, гармоничные и эстетичные пространства, отражающие индивидуальность каждого клиента.
						<br />
					</p>
					<p>
						Для меня дизайн – это не просто работа, а творческий процесс, в котором каждый элемент играет свою роль в
						создании пространства. Моя цель – помочь вам воплотить ваши идеи и создать интерьер, который будет отражать
						ваш стиль и любые ваши потребности.
						<br />
					</p>
					<p>Буду рада работать с вами и создавать уникальные решения для вашего пространства!</p>
				</div>
				<div className="profile-image">
					<img src={photo} alt="Евгения Гридина" />
				</div>
			</article>
		</div>
	);
};
export default AboutMe;
