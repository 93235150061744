import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";

const Footer = () => {
	const projects = require("../projects.json");
	const [currentImage, setCurrentImage] = useState(null);
	const [isImageVisible, setIsImageVisible] = useState(true);

	useEffect(() => {
		const getRandomWideImage = () => {
			const allWideImages = projects
				.flatMap((project) => project?.images?.filter((image) => image.width >= image.height))
				.filter(Boolean);

			if (allWideImages.length > 0) {
				return allWideImages[Math.floor(Math.random() * allWideImages.length)];
			}
			return null;
		};

		const updateImage = () => {
			setIsImageVisible(false);

			setTimeout(() => {
				const newImage = getRandomWideImage();
				setCurrentImage(newImage);
				setIsImageVisible(true);
			}, 500); // Make sure the fade out completes
		};

		updateImage();
		const interval = setInterval(updateImage, 3000);

		return () => clearInterval(interval);
	}, [projects]);

	return (
		<footer className="footer">
			<div className="footer-links">
				<NavLink to="/" className="footer-link" activeclassname="active">
					Главная
				</NavLink>
				<NavLink to="/about" className="footer-link" activeclassname="active">
					Обо мне
				</NavLink>
				<NavLink to="/projects" className="footer-link" activeclassname="active">
					Проекты
				</NavLink>
				<NavLink to="/services" className="footer-link" activeclassname="active">
					Услуги и цены
				</NavLink>
			</div>

			<div className="footer-slider-container">
				<div className="footer-slider">
					<div className="slider-wrapper">
						{currentImage ? (
							<img
								src={currentImage.url}
								alt={currentImage.projectTitle}
								className={`slider-image ${isImageVisible ? "" : "hidden"}`}
							/>
						) : (
							<p>Завантаження...</p>
						)}
					</div>
				</div>
			</div>

			<div className="footer-contacts">
				<p className="footer-contact">
					<a href="tel:+79816842836" className="contact-link">
						+7 981 684 28 36
					</a>
				</p>
				<p className="footer-contact">
					<a href="mailto:janegridina@gmail.com" className="contact-link">
						janegridina@gmail.com
					</a>
				</p>
				<div className="footer-socials">
					<a href="https://t.me/+79816842836" target="_blank" rel="noopener noreferrer">
						<img
							src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Telegram_Messenger.png/240px-Telegram_Messenger.png"
							alt="Telegram"
							className="footer-icon"
						/>
					</a>
					<a href="https://wa.me/79816842836" target="_blank" rel="noopener noreferrer">
						<img
							src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/240px-WhatsApp.svg.png"
							alt="WhatsApp"
							className="footer-icon"
						/>
					</a>
					<a href="https://vk.com/janegridinadesign" target="_blank" rel="noopener noreferrer">
						<img
							src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/VK.com-logo.svg/240px-VK.com-logo.svg.png"
							alt="VK"
							className="footer-icon"
						/>
					</a>
					<a href="https://www.behance.net/janegridina" target="_blank" rel="noopener noreferrer">
						<img
							src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Behance_while_icon.svg/270px-Behance_while_icon.svg.png"
							alt="Behance"
							className="footer-icon"
						/>
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
